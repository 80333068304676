import { apiPost } from '../api';

interface TLoginProps {
    username: string;
    password: string;
}

export const login = async (props: TLoginProps): Promise<void> => {
    return apiPost<void, TLoginProps>('/auth/login', props);
};
