export const contractKeys = {
    list: ['contracts'] as const,
    listForChat: ['contracts', 'chat'] as const,
    listAdmin: (archived: boolean, olderThanOneYear?: boolean) =>
        [
            'contracts',
            'admin',
            archived ? 'archived' : 'active',
            olderThanOneYear ? 'oderThanOneYear' : 'newerThanOneYear',
        ] as const,
    listEk: ['contracts', 'ek'] as const,
    single: (id: string) => ['contract', id] as const,
    whoAmI: ['whoAmI'] as const,
};

export const singleContractQueryReturn = `
            id
            fileNumber
            created
            saved
            infoMailAP1
            infoMailAP2
            surveyDateConfirmationAP
            apiSource
            canceled
            company {
                id
                name
                nameShort
            }
            contractType
            downloaded
            fromAPI
            creator {
                id
                name
                fields{
                     firstName
                     lastName
                }
            }
            state
            archived
            fee
            deadlineSV
            deadlineEK
            inCorrection
            noteCorrection
            favoured
            note1
            note2
            validator {
                id
                name
                email
            }
            address {
                postalCode
                city
                street
                houseNo
                country
                note
            }
            contact1 {
                firstName
                lastName
                mail
                mobile
                phone
                position
                title
            }
            contact1InUse
            contact2 {
                firstName
                lastName
                mail
                mobile
                phone
                position
                title
            }
            contact2InUse
            position {
                latitude
                longitude
                corrected
            }
            conversation {
                id
                subject
            }
            express
            minUnits
            maxUnits
            objectType
            surveyor {
                id
                email
                name
                fields {
                    firstName
                    lastName
                    country
                }
            }
            surveyDate
            formType
            offers {
                surveyor {
                    id
                    name
                    fields {
                        firstName
                        lastName
                        longitude
                        latitude
                    }
                }
                amount
                applied
                confirmed
            }
            surveys {
                id
                name
                type
                form
                deleted
                photo
                saved
            }
            attachments {
                id
                fileName
                displayable
                mime_type
            }
            conversation {
                id
            }`;
