import { NextPage } from 'next';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { RefreshIcon } from '@heroicons/react/solid';
import { useQueryClient } from 'react-query';
import { useSyncContext } from '../src/context/syncContext';
import { useLogin } from '../src/hooks/api/user/useLogin';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../src/components/Auth/userAtom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLockKeyhole } from '@fortawesome/pro-duotone-svg-icons';
import { toast } from 'react-toastify';
import { SafariModal } from '../src/components/SafariModal/SafariModal';
import { useWhoAmIdQuery } from '../src/hooks/api/user/useWhoAmIdQuery';

const Login: NextPage = () => {
    const queryClient = useQueryClient();

    const [, setSyncContext] = useSyncContext();

    const [, setAuthenticated] = useState(false);

    const { query, push } = useRouter();

    // useWhoAmI(authenticated);
    useWhoAmIdQuery(true);

    const user = useRecoilValue(userAtom);

    const { mutateAsync: login, isLoading } = useLogin();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    //redirects the user to index if authenticated
    useEffect(() => {
        if (user.id) {
            setSyncContext((current) => {
                return {
                    ...current,
                    syncing: false,
                    firstSync: false,
                };
            });
            queryClient.clear();

            const redirect = query.redirect as string;

            if (redirect) {
                void push(Buffer.from(decodeURIComponent(redirect), 'base64').toString('utf-8'));
            } else if (user.roles) {
                if (user.roles.some((role) => role.access.includes('admin'))) {
                    void push('/admin/');
                } else if (user.roles.some((role) => role.access.includes('ek'))) {
                    void push('/customer/');
                } else if (user.roles.some((role) => role.access.includes('sv'))) {
                    void push('/');
                }
            } else {
                void push('/');
            }
        }
    }, [push, query.redirect, queryClient, setSyncContext, user]);

    return (
        <>
            <div className="flex min-h-screen bg-white">
                <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                    <div className="mx-auto w-full max-w-sm lg:w-96">
                        <div>
                            <img
                                className="h-32 w-auto"
                                src="assets/immoLogo.svg"
                                alt="immobilienbesucher.de"
                            />
                        </div>

                        <div className="relative flex w-full flex-col items-center justify-center gap-1 pt-3 text-neutral-400">
                            App Version 2.0
                        </div>
                        <div className="mt-4">
                            <div className="mt-6">
                                <form action="#" method="POST" className="space-y-6">
                                    <div className="-space-y-px rounded-md shadow-sm">
                                        <div id="user_name">
                                            <label htmlFor="username" className="sr-only">
                                                Benutzername
                                            </label>
                                            <input
                                                autoComplete="off"
                                                id="input-username"
                                                name="benutzername"
                                                data-test-id="login-username"
                                                required
                                                className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-primary focus:outline-none focus:ring-primary sm:text-sm"
                                                placeholder="Benutzername"
                                                onChange={(event) => {
                                                    setUsername(event.target.value);
                                                }}
                                            />
                                        </div>
                                        <div id="password">
                                            <label htmlFor="password" className="sr-only">
                                                Password
                                            </label>
                                            <input
                                                id="input-password"
                                                name="password"
                                                type="password"
                                                data-test-id="login-password"
                                                autoComplete="off"
                                                required
                                                className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-primary focus:outline-none focus:ring-primary sm:text-sm"
                                                placeholder="Password"
                                                onChange={(event) => {
                                                    setPassword(event.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="flex items-center justify-between">
                                        <div className="text-sm">
                                            <Link href="/requestnewpassword">
                                                <a
                                                    id="forgot_password"
                                                    href="#"
                                                    className="font-medium text-primary hover:text-primary-light"
                                                >
                                                    Password vergessen?
                                                </a>
                                            </Link>
                                        </div>
                                    </div>

                                    <div>
                                        <button
                                            onClick={(e) => {
                                                void login({ username, password })
                                                    .then(() => {
                                                        setAuthenticated(true);
                                                    })
                                                    .catch(() => {
                                                        toast.error(
                                                            'Die angegebenen Daten sind unbekannt.'
                                                        );
                                                    });
                                                e.preventDefault();
                                            }}
                                            id="submit-button"
                                            data-test-id="login-submit"
                                            type="submit"
                                            className="group relative flex w-full justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                                        >
                                            {!isLoading && (
                                                <div>
                                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                                        <FontAwesomeIcon icon={faLockKeyhole} />
                                                    </span>
                                                    Anmelden
                                                </div>
                                            )}
                                            {isLoading && (
                                                <RefreshIcon className="w-5 animate-spin" />
                                            )}
                                        </button>
                                    </div>
                                    <div className="flex justify-between">
                                        <Link href="/impressum">
                                            <a
                                                id="impressum"
                                                href="#"
                                                className=" text-secondary hover:text-secondary-dark"
                                            >
                                                Impressum
                                            </a>
                                        </Link>
                                        <Link href="/datenschutz">
                                            <a
                                                id="datenschutz"
                                                href="#"
                                                className=" text-secondary hover:text-secondary-dark"
                                            >
                                                Datenschutz
                                            </a>
                                        </Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative hidden w-0 flex-1 lg:block">
                    <img
                        className="absolute inset-0 h-full w-full object-cover"
                        src="assets/loginIMG.jpg"
                        alt=""
                    />
                </div>
            </div>

            <SafariModal />
        </>
    );
};

export default Login;
